import React, { createContext } from 'react';
export const MixContext = createContext({});

const MixProvider = ({children}) => {
  return <MixContext.Provider value={{
    totalSize:0,
    needAction:false,
    mixCanPlay:true,
    files:[],
    events:[],
    setContext:()=>{},
    triggerSon:()=>{},
    mixLoaded:100,
    mixReady:true,
    muteAll:false,
    setMuteAll:()=>{},
    sons:[],
    players:[]
  }}>
      {children}
  </MixContext.Provider>;
}
export default MixProvider;
