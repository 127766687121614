import React, { createContext } from "react";
export const PreloadContext = createContext({});
const PreloadProvider = ({children})=>{
return (
    <PreloadContext.Provider value={{
      preloadList:[],
      preloadLaterList:[],
      files:[],
      file:(url)=>url,
      assetsReady:true,
      assetsLoaded:100,
      totalSize:0,
    }}>
        {children}
    </PreloadContext.Provider>
  );
}
export default PreloadProvider;
