import React, { useContext,useState,useEffect,useCallback,useMemo } from "react";
import "./Lettre.scss";
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { useParams,useNavigate } from "react-router-dom";
import Tapuscrit from 'components/Tapuscrit';
import LettreInner from "shared/components/Lettre";
import Menu from "shared/components/Menu";
import Plus from "components/Plus";
import { MixContext } from "shared/providers/MixProvider";
import { PreloadContext } from "providers/PreloadProvider";

function Lettre() {
  const { id } = useParams();
  const { triggerSon, events } = useContext(MixContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const { getDoc } = useContext(ModelContext);
  const { file } = useContext(PreloadContext);
  const doc=getDoc(id);
  const navigate=useNavigate();
  const [tapuscritShow,setTapuscritShow] = useState(false);
  const [hide,setHide] = useState(false);
  const [ plus, setPlus ] = useState(false);
  const params=useMemo(()=>{
    if(doc) {
      const {x:xDoc,y:yDoc,scale:scaleDoc}=doc;
      return appState.lettresParams ? appState.lettresParams[doc._id] || {x:xDoc,y:yDoc,scale:scaleDoc} : {x:xDoc,y:yDoc,scale:scaleDoc};
    }
    return {x:0,y:0,scale:5}
  },[appState,doc]);
  const setParams=useCallback((x,y,scale)=>{
    setAppState((state)=>{
      return {
        ...state,
        lettresParams:{
          ...state.lettresPositions,
          [id]:{x,y,scale},
        }
      }
    });
  },[setAppState,id]);
  const handlePlusClose=useCallback(()=>setPlus(false),[setPlus]);
  const setPosition=useCallback((x,y)=>{
    setParams(x,y,params.scale);
  },[setParams,params]);
  const setScale=useCallback((scale)=>{
    setParams(params.x,params.y,scale);
  },[setParams,params]);
  const playState=useMemo(()=>
    (events.filter((o)=>['lettrePlay/'+id,'lettrePause/'+id,'lettreStop/'+id].indexOf(o.hook)!==-1)[0] || {action:'stop'}).action
  ,[events,id]);
  const play=useCallback(()=> {
    triggerSon('lettrePlay/'+id);
  },[triggerSon,id]);
  const pause=useCallback(()=> {
    triggerSon('lettrePause/'+id);
  },[triggerSon,id]);
  const stop=useCallback(()=> {
    triggerSon('lettreStop/'+id);
  },[triggerSon,id]);
  const hideMenu=useCallback(()=>setHide(true),[setHide]);
  const showMenu=useCallback(()=>setHide(false),[setHide]);
  const showMainMenu=useCallback(()=>
    setAppState((state)=>{return {...state,mainMenu:{...state.mainMenu, show:true}}})
  ,[setAppState]);
  const topMenu=useMemo(()=>[{className:'menu',onClick:showMainMenu}],[showMainMenu]);
  useEffect(()=>{
    setAppState((state)=>{return {
      ...state,
      contextMenu:[
        {className:'portrait',onClick:()=>navigate('/player/'+doc.portraitId)},
      ]
    }});
    return ()=>{
      setAppState((state)=>{return {
        ...state,
        contextMenu:[]
      }});
    }
  },[setAppState,id,navigate,doc.portraitId]);
  const bottomMenu=useMemo(()=>[
    {className:'lettres',onClick:()=>navigate('/lettres/'+doc.portraitId)},
    {className:'tapuscrit',onClick:()=>setTapuscritShow(true)},
    {className:'plus',onClick:()=>setPlus(true)},
    {className:'voix',onClick:()=>play(), condition:playState==='stop'},
    {className:'pause',onClick:()=>pause(), condition:playState==='play'},
    {className:'play',onClick:()=>play(), condition:playState==='pause', secondary:{className:'stop',onClick:()=>stop()}},
  ],[navigate,setTapuscritShow,setPlus,play,pause,stop,playState,doc.portraitId]);
  return doc && params ? <div className={'lettre'+(hide ? ' menu-hide' : '')}>
      <LettreInner id={id} onPointerDown={hideMenu} onPointerUp={showMenu} setScale={setScale} setPosition={setPosition} position={params} scale={params.scale} file={file}/>
      <Menu type='top' justify='flex-end' items={topMenu} fullWidth/>
      <Menu type='bottom' justify='space-evenly' items={bottomMenu}/>
      <Tapuscrit lettre={doc} show={tapuscritShow} onClose={()=>setTapuscritShow(false)}/>
      <Plus portraitId={doc.portraitId} show={plus} onClose={handlePlusClose} file={file}/>
  </div> : '';
}

export default Lettre;
