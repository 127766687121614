import React, {useContext, useState, useEffect, useCallback, useMemo, useLayoutEffect, useRef } from "react";
import "./Lettres.scss";
import { useParams,useNavigate,Link } from "react-router-dom";
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, {
  Navigation
} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import Conf from 'Conf';
import PlayerConf from 'shared/Conf';
import Menu from 'shared/components/Menu';
import Plus from 'components/Plus';
import { MixContext } from "shared/providers/MixProvider";
import { PreloadContext } from "providers/PreloadProvider";

SwiperCore.use([Navigation]);

function Lettres() {
  const { id } = useParams();
  const { triggerSon,mixReady,setContext } = useContext(MixContext);
  const { getCollection } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const { file } = useContext(PreloadContext);
  const lettres=useMemo(()=>getCollection('lettre').filter((o)=>o.portraitId===id),[getCollection,id]);
  const lettreId=appState.selectedLettres ? (appState.selectedLettres[id] || null) : null;
  const setLettreId=useCallback((lettreId)=>{
    setAppState((state)=>{
      const selectedLettres={...state.selectedLettres,[id]:lettreId} || {[id]:lettreId};
      return {...state,selectedLettres};
    })
  },[setAppState,id]);
  useEffect(()=>{
    setContext({type:'lettres/'+id});
    return ()=>{
      setContext(null);
    }
  },[setContext,id]);
  useEffect(()=>{
    if (lettres.length>0) setLettreId(lettres[0]._id);
  },[lettres,setLettreId])
  const handleSlideChange=(swiper)=>{
    if (lettres.length>0) setLettreId(lettres[(swiper.activeIndex) % lettres.length]._id);
  }
  const handleInit=(swiper)=>{
    if (lettreId) swiper.slideTo(lettres.findIndex((o)=>o._id===lettreId),0);
  }
  const navigate=useNavigate();
  const container = useRef(null);
  const [ scale, setScale ] = useState(1);
  const [ left, setLeft ] = useState(0);
  const [ top, setTop ] = useState(0);
  const [ plus, setPlus ] = useState(false);
  useLayoutEffect(()=>{
    const handleResize=()=>{
      if (container.current) {
        const { width,height } = container.current.getBoundingClientRect();
        //console.log(width,height);
        const ratio=height/width;
        const R=PlayerConf.height/PlayerConf.width;
        let scale=1;
        let l=0;
        let t=0;
        if (ratio<R) {
          scale=height/PlayerConf.height;
          l=(width-PlayerConf.width*scale)/2;
        } else {
          scale=width/PlayerConf.width;
          t=(height-PlayerConf.height*scale)/2;
        }
        //console.log(width,height,PlayerConf.width,PlayerConf.height,scale)
        setScale(scale);
        setLeft(l);
        setTop(t);
        const root = document.documentElement;
        root.style.setProperty('--pixelSize', (1.2/scale)+"px");
      }
    }
    window.addEventListener('resize',handleResize);
    handleResize();
    return ()=>{
      window.removeEventListener('resize',handleResize);
    }
  },[setScale,setLeft]);
  useEffect(()=>{
    if (mixReady) {
      triggerSon('lettres');
    }
  },[mixReady,triggerSon]);
  useEffect(()=>{
    if (mixReady && lettreId) {
      triggerSon('lettres/'+lettreId);
    }
  },[lettreId,mixReady,triggerSon]);
  const showMainMenu=()=>setAppState((state)=>{return {...appState,mainMenu:{...appState.mainMenu, show:true}}});
  const topMenu=[{className:'menu',onClick:showMainMenu}];
  const bottomMenu=[
    {className:'home',onClick:()=>navigate('/themes')},
    {className:'portrait',onClick:()=>navigate('/player/'+id)},
    {className:'plus',onClick:()=>setPlus(true)},
  ];
  return <div className='lettres' ref={container}>
    <div className='lettres-inner' style={{left,top,transform:'scale('+scale+')'}}>
      <div className='lettres-chooser'>
        <Swiper
          navigation
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={handleSlideChange}
          onSwiper={handleInit}
        >
          {lettres.map((lettre)=><SwiperSlide key={lettre._id}>
            <Link to={'/lettre/'+lettre._id}>
              <div className="lettres-lettre-inner">
              {lettre.vignette && lettre.vignette[0] && <div className='lettres-lettre-vignette' style={{backgroundImage:`url(${file(Conf.filesUrl+lettre.vignette[0].url+'-300')})`}}/>}
              <div className="lettres-lettre-date">{lettre.date}</div>
              <div className="lettres-lettre-from">{lettre.from}</div>
              <div className="lettres-lettre-arrow"></div>
              <div className="lettres-lettre-to">{lettre.to}</div>
              </div>
            </Link>
          </SwiperSlide>
          )}
        </Swiper>
      </div>
    </div>
    <Menu type='top' justify='flex-end' items={topMenu} fullWidth/>
    <Menu type='bottom' justify='space-evenly' items={bottomMenu}/>
    <Plus portraitId={id} show={plus} onClose={()=>setPlus(false)}/>
  </div>;
}

export default Lettres;
