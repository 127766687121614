import React, { useContext,useState,useEffect,useRef } from "react";
import "./Lettre.scss";
import { ModelContext } from "providers/ModelProvider";
import { TransformWrapper, TransformComponent } from "shared/lib/react-zoom-pan-pinch";
import Conf from 'Conf';
import { MixContext } from "shared/providers/MixProvider";

function Lettre({id, setScale=(s)=>null, scale=null, setPosition=(x,y)=>null,onPointerDown=(e)=>null,onPointerUp=(e)=>null,position=null,file=(url)=>url}) {
  const { triggerSon,mixReady,setContext } = useContext(MixContext);
  const { getDoc } = useContext(ModelContext);
  const doc=getDoc(id);
  const [dim,setDim] = useState(null);
  const mainRef=useRef(null);
  useEffect(()=>{
    setContext({type:'lettre',id:id});
    return ()=>{
      setContext(null);
    }
  },[setContext,id]);
  useEffect(()=>{
    if (mixReady) {
      triggerSon('lettre/'+id);
    }
  },[mixReady,triggerSon,id]);
  useEffect(()=>{
    if (mainRef.current) {
      const {width,height}=mainRef.current.getBoundingClientRect();
      setDim({width,height});
    }
  },[setDim]);
  return doc ? <div className='lettre-inner' ref={mainRef} onPointerDown={onPointerDown} onPointerUp={onPointerUp}>
      {dim && <>
        <TransformWrapper
        initialScale={scale ? scale : doc.scale}
        minScale={1/2}
        maxScale={3}
        initialPositionX={position ? position.x : doc.x}
        initialPositionY={position ? position.y : doc.y}
        limitToBounds={false}
        panning={{velocityDisabled:true}}
        onZoom={(a,b)=>setScale(a.state.scale)}
        onPanningStop={(a,b)=>setPosition(a.state.positionX,a.state.positionY)}
        >
          <TransformComponent>
            <div className="lettre-viewport">
              <div className="lettre-viewport-inner">
                {doc.scan.length>0 && ( doc.scan[0].type==='image/svg+xml' ?
                  <img src={file(Conf.filesUrl+doc.scan[0].url)} alt="test" />
                  : <img src={file(Conf.filesUrl+doc.scan[0].url+'-3000')} alt="test" />
                )}
              </div>
            </div>
          </TransformComponent>
        </TransformWrapper>
        <div className="lettre-mask"></div>
      </>}
  </div> : '';
}

export default Lettre;
